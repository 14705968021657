import React, { RefObject } from 'react';

export interface IHeaderProps {
    height: number;
    scrollContainerRef?: RefObject<HTMLElement>;
}

export interface IContextProps {
    totalHeaderOffset: number;
    headerStack: IHeaderProps[];
    scrollContainerRef?: RefObject<HTMLElement>;
}

const FixedHeaderContext = React.createContext<IContextProps>({
    totalHeaderOffset: 0,
    headerStack: [],
});

export default FixedHeaderContext;