import { withDebounce } from '@frontend/jetlend-core/src/async/debounce';

const CSS_VARIABLE_NAME = '--vh';

let refCount = 0;

const recomputeVhUnit = withDebounce(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(CSS_VARIABLE_NAME, `${vh}px`);
});

/**
 * TODO
 * @category Utils
 */
export function requireViewportHandler() {
    if (refCount === 0) {
        window.addEventListener('resize', recomputeVhUnit);
    }

    refCount++;
}

/**
 * TODO
 * @category Utils
 */
export function releaseViewportHandler() {
    refCount--;

    if (refCount <= 0) {
        window.removeEventListener('resize', recomputeVhUnit);
        refCount = 0;
    }
}