'use client';

import React, {
    useEffect,
    useState,
} from 'react';
import ReactDOM from 'react-dom';

export interface IProps extends React.PropsWithChildren {
    selector?: () => HTMLElement|undefined;
}

const DEFAULT_SELECTOR: IProps['selector'] = () => document?.body;

const ClientPortal: React.FC<IProps> = props => {
    const {
        selector = DEFAULT_SELECTOR,
    } = props;

    const [ containerElement, setContainerElement ] = useState<HTMLElement|undefined>(undefined);

    useEffect(() => {
        setContainerElement(selector);
    }, [ selector ]);

    if (containerElement) {
        return ReactDOM.createPortal(props.children, containerElement);
    }

    return null;
};

export default ClientPortal;
