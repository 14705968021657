import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    IApiHandler,
    networkStateHandler,
} from '../ducks/api';
import { useActions } from './useActions';
import {
    IBaseApiHookOptions,
    isFetchRequiredByOptions,
} from './common';

type HookReturnType<TApiModel> = [
    TApiModel|null|undefined,
    boolean,
    () => void
];

/**
 * Хук для работы с {@link simpleApiHandler} внутри компонентов.
 *
 * @param handler АПИ хендлер, реализующий интерфейс {@link IApiHandler}
 * @param options Дополнительные параметры для настройки поведения загрузки данных
 * @returns Данные, флаг загрузки, функция для принудительного обновления данных.
 * @category Hooks
 *
 * @see {@link simpleApiHandler}
 * @see {@link useCollectionApiHandler}
 * @see {@link useListApiHandler}
 * @see {@link useIsApiFetching}
 *
 * @example
 *  const [
 *      // Загруженные данные, могут быть null или undefined, пока АПИ не вернет корректные данные
 *      accountInfo,
 *      // Индикатор, что данные загружаются или обновляются из АПИ в данный момент
 *      isFetching,
 *      // Принудительное обновление данных
 *      fetchUpdate
 *  ] = useSimpleApiHandler(accountInfoHandler);
 *
 *  // Загружаем данные, только в том случае, когда всплывающее окно было открыто
 *  const [data] = useSimpleApiHandler(accountDetailsHandler, {
 *      fetchWhen: isOpen,
 *  });
 */
export default function useSimpleApiHandler<TApiModel>(handler: IApiHandler<TApiModel>, options?: IBaseApiHookOptions): HookReturnType<TApiModel> {
    // We are using network state to refetch data when global cache cleared
    const networkState = useSelector(networkStateHandler.selector);

    const data = useSelector(handler.selector);
    const isFetching = useSelector(handler.isFetching);

    const [ fetch, fetchUpdate ] = useActions([
        handler.fetch,
        handler.fetchUpdate,
    ]);

    const fetchRequired = isFetchRequiredByOptions(options);
    useEffect(() => {
        if (fetchRequired) {
            fetch();
        }
    }, [ fetch, networkState.cacheVersion, fetchRequired ]);

    return [
        data,
        isFetching,
        fetchUpdate,
    ];
}
