'use client';

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './layoutFooter.module.scss';

type FooterSectionType = 'apps'|'notes';

interface IPortalNode {
    id: number;
    sectionType: FooterSectionType;
    node: React.ReactNode;
}

interface IFooterPortalContext {
    addChild(sectionType: FooterSectionType, node: React.ReactNode): () => void;
    children: IPortalNode[];
}

const layoutFooterContext = createContext<IFooterPortalContext>({
    addChild: () => () => undefined,
    children: [],
});

export function LayoutFooterPortalProvider({ children }: React.PropsWithChildren) {
    const idCounterRef = useRef(0);
    const [ nodeList, setNodeList ] = useState<IPortalNode[]>([]);

    const addChild = useCallback((sectionType: FooterSectionType, children: React.ReactNode) => {
        const id = idCounterRef.current++;
        const node: IPortalNode = {
            id,
            sectionType,
            node: children,
        };

        setNodeList(prevNodeList => [
            ...prevNodeList,
            node,
        ]);

        return () => {
            setNodeList(prevNodeList => prevNodeList.filter(node => node.id !== id));
        };
    }, [ setNodeList ]);

    // TODO Подумать на тему useMemo здесь
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const context: IFooterPortalContext = {
        addChild,
        children: nodeList,
    };

    return (
        <layoutFooterContext.Provider value={context}>
            {children}
        </layoutFooterContext.Provider>
    );
}

export function LayoutFooterPortalRenderer({
    className,
    sectionType,
    allowEmpty,
}: { className: string; sectionType: FooterSectionType; allowEmpty?: boolean }) {
    const context = useContext(layoutFooterContext);
    if (!context || !context.children) {
        return null;
    }

    const filteredNodes = context.children.filter(node => node.sectionType === sectionType);
    if (!filteredNodes || filteredNodes.length === 0) {
        if (allowEmpty) {
            return (
                <section className={mergeClassNames([styles['section'], className])} />
            );
        }

        return null;
    }

    return (
        <>
            {filteredNodes.map(node => (
                <section key={node.id} className={mergeClassNames([styles['section'], className])}>
                    {node.node}
                </section>
            ))}
        </>
    );
}

export default function LayoutFooterSectionPortal({
    sectionType = 'notes',
    children,
}: React.PropsWithChildren<{ sectionType?: FooterSectionType }>) {
    const context = useContext(layoutFooterContext);

    useEffect(() => context.addChild(sectionType, children), [ sectionType, context.addChild, children ]);

    return null;
}