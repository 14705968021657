import {
    useRef,
    MutableRefObject,
} from 'react';

/**
 * Создает React Ref объект для переданных данных.
 * Данные внутри этого объекта будут автоматически обновляться.
 *
 * Данный хук полезен при оптимизации сложных компонентов, где важно передавать данные в обработчики событий,
 *  не вызывая их пересоздания.
 *
 * @param data Любые данные, которые будут помещены внутри React Ref объекта.
 * @returns React Ref объект с обновляемыми данными
 * @category Hooks
 *
 * @example
 * // Используйте хук, чтобы обернуть данные и получите ссылку на React Ref объект с актуальными данными внутри
 * const tableDataRef = useDataRef(props.data);
 * const openCellEditorRef = useDataRef(props.openCellEditor);
 *
 * // Создаем обработчик события с зависимостями на Ref объекты, вместо прямой зависимости на данные
 * const didCellClicked = useCallback(cellId => {
 *     // Здесь данные будут всегда актуальные, т.к. они автоматически обновляются внутри Ref объекта
 *     if (isCellExists(tableDataRef.current, cellId)) {
 *         openCellEditorRef.current(cellId);
 *     }
 * }, [ tableDataRef, openCellEditorRef ]);
 *
 * return (
 *     // Компонент не будет перерисован, т.к. параметры не обновились
 *     <HighWeightTableComponent
 *         onCellClicked={didCellClicked}
 *     />
 * );
 */
export function useDataRef<T>(data: T): MutableRefObject<T> {
    const ref = useRef<T>(data);
    ref.current = data;

    return ref;
}
