type ListenerCallback = (e: MouseEvent) => void;

let initialized = false;
const listeners: ListenerCallback[] = [];
const elements: HTMLElement[] = [];

function handleOutsideClick(e: MouseEvent) {
    listeners.forEach(listener => {
        listener(e);
    });
}

function init() {
    if (initialized) {
        return;
    }

    document.addEventListener('click', handleOutsideClick);
    elements.forEach(element => {
        element.addEventListener('click', handleOutsideClick);
    });

    initialized = true;
}

function release() {
    if (!initialized) {
        return;
    }

    document.removeEventListener('click', handleOutsideClick);
    elements.forEach(element => {
        element.removeEventListener('click', handleOutsideClick);
    });

    initialized = false;
}

/**
 * Register outside container element, that stop event propagation to document.
 *
 * @param element The element that can trigger outside click
 * @category Utils
 */
export function addOutsideClickElement(element: HTMLElement) {
    if (!element) {
        console.error('Element should be provided');
        return;
    }

    const elementIndex = elements.indexOf(element);
    if (elementIndex >= 0) {
        return;
    }

    elements.push(element);

    if (initialized) {
        element.addEventListener('click', handleOutsideClick);
    }
}

/**
 * Unregister outside container element, that stop event propagation to document.
 *
 * @param element The element that can trigger outside click
 * @category Utils
 */
export function removeOutputClickElement(element: HTMLElement) {
    if (!element) {
        console.error('Element should be provided');
        return;
    }

    const elementIndex = elements.indexOf(element);
    if (elementIndex >= 0) {
        elements.splice(elementIndex, 1);

        if (initialized) {
            element.removeEventListener('click', handleOutsideClick);
        }
    }
}

/**
 * Register outside click handler, that fired when document element clicked,
 *  or any registered outside elements.
 *
 * @param listener
 * @Category Utils
 */
export function addOutsideClickHandler(listener: ListenerCallback) {
    if (!listener) {
        console.error('Listener should be provided');
        return;
    }

    const listenerIndex = listeners.indexOf(listener);
    if (listenerIndex >= 0) {
        return;
    }

    if (listeners.length === 0) {
        init();
    }

    listeners.push(listener);
}

/**
 * Unregister outside click handler, that fired when document element clicked,
 *  or any registered outside elements.
 *
 * @param listener
 * @Category Utils
 */
export function removeOutsideClickHandler(listener: ListenerCallback) {
    if (!listener) {
        console.error('Listener should be provided');
        return;
    }

    const listenerIndex = listeners.indexOf(listener);
    if (listenerIndex >= 0) {
        listeners.splice(listenerIndex, 1);
    }

    if (listeners.length === 0) {
        release();
    }
}