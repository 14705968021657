import React from 'react';
import NextImage from 'next/image';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

function buildCorrectSrc(src: string|StaticImport): string|StaticImport {
    if (typeof src !== 'string') {
        return src;
    }

    if (src.startsWith('/wp-content') || src.startsWith('/media')) {
        return `${process.env.NEXT_PUBLIC_JETLEND_MEDIA_ENDPOINT ?? ''}${src}`;
    }

    if (src.startsWith('/')) {
        return `${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}${src}`;
    }

    return src;
}

const Image: React.FC<React.ComponentProps<typeof NextImage>> = props => {    // eslint-disable-next-line jsx-a11y/alt-text
    return <NextImage {...props} src={buildCorrectSrc(props.src)} />;
};

export default Image;
