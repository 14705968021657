import React from 'react';
import FixedHeaderContext, { IHeaderProps } from './FixedHeaderContext';

export type IProps = IHeaderProps & React.PropsWithChildren;

const UseFixedHeader: React.FC<IProps> = props => {
    const {
        children,
        ...headerConfig
    } = props;

    return (
        <FixedHeaderContext.Consumer>
            {context => (
                <FixedHeaderContext.Provider
                    // TODO Refactor it later
                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                    value={{
                        headerStack: [...context.headerStack, headerConfig ],
                        totalHeaderOffset: headerConfig.scrollContainerRef
                            ? headerConfig.height
                            : context.totalHeaderOffset + headerConfig.height,
                        scrollContainerRef: headerConfig.scrollContainerRef,
                    }}
                >
                    {children}
                </FixedHeaderContext.Provider>
            )}
        </FixedHeaderContext.Consumer>
    );
};

export default UseFixedHeader;
